@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
body {
    background-color: #e0e3e8;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #475F7B;
}

a, a:hover, a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

/* ------------ */

.card {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    border: none;
}
.card-body {
    padding: 30px;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.form-submit{
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
    width: 100%;
    flex: 1 1;
}

.kb-modal-data-title {
    margin-bottom: 10px;
}
.kb-data-title h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
}
.kb-file-upload {
    margin-bottom: 20px;
}
.file-upload-box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}
.file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.file-link{
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}
.file-upload-box .file-link:hover{
    text-decoration: none;
}
.file-atc-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.file-image {
    /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}
.file-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}
.file-detail {
    flex: 1 1;
    width: calc(100% - 210px);
}
.file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
}
.file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
}
.file-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.file-action-btn {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}
.file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline;
}
.file-atc-box:last-child{
    margin-bottom: 0;
}
